export default (customerKey, configId) => {
  if (typeof window !== 'undefined') {
    window.PUZZEL_EUWA_CONFIG = { customerKey, configId }
    var loader = document.createElement('script')
    loader.src = 'https://euwa.puzzel.com/loader/index.js'
    document.body.append(loader)
    loader.onload = function() {
      new window.EUWALoader({ customerKey, configId }).load()
    }
  }
}
