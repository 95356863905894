import AccordionComponent from './Accordion/Accordion'
import BlocksComponent from './Blocks/Blocks'
import CommercialPropertyComponent from './CommercialProperty/CommercialProperty'
import CardHeroComponent from './Card/CardHero/CardHero'
import CardGridComponent from './Card/CardGrid/CardGrid'
import FlickrAlbumComponent from './FlickrAlbum/FlickrAlbum'
import ImagesBlockComponent from './ImagesBlock/ImagesBlock'
import PageContentComponent from './PageContent/PageContent'
import PageSectionLinksComponent from './PageSectionLinks/PageSectionLinks'
import StepByStepComponent from './StepByStep/StepByStep'
import QuestionComponent from './Question/Question'

export const Accordion = AccordionComponent
export const Blocks = BlocksComponent
export const ImagesBlock = ImagesBlockComponent
export const PageContent = PageContentComponent
export const PageSectionLinks = PageSectionLinksComponent
export const CommercialProperty = CommercialPropertyComponent
export const CardHero = CardHeroComponent
export const CardGrid = CardGridComponent
export const FlickrAlbum = FlickrAlbumComponent
export const StepByStep = StepByStepComponent
export const Question = QuestionComponent

export default {
  Accordion,
  Blocks,
  ImagesBlock,
  PageContent,
  PageSectionLinks,
  CommercialProperty,
  CardHero,
  CardGrid,
  FlickrAlbum,
  StepByStep,
  Question,
}
