const regex = /\s(\w+)="([^"]+)"/g

export const getStyleObjFromString = str =>
  str
    .replace(/\s/g, '')
    .split(';')
    .map(prop => prop.split(':'))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})

export const getPropsFromDOMString = DOMString =>
  Array.from(DOMString.matchAll(regex)).reduce(
    (acc, [, key, value]) => ({
      ...acc,
      [key]: key === 'style' ? getStyleObjFromString(value) : value,
    }),
    {}
  )
