import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion'
import { FaPlus, FaMinus } from 'react-icons/fa'

import styles from './Accordion.module.scss'

export const query = graphql`
  fragment accordionFragment on WordPressAcf_accordion {
    accordions {
      accordion_title
      accordion_content
    }
  }
`

const NewsFeatured = ({ accordions }) => (
  <div className={styles.wpAccordions}>
    <Accordion
      className={styles.accordion}
      allowZeroExpanded={true}
      allowMultipleExpanded={true}
    >
      {accordions.map(({ accordion_title, accordion_content }) => (
        <AccordionItem key={accordion_title} className={styles.accordionItem}>
          <AccordionItemHeading className={styles.accordionItemHeading}>
            <AccordionItemButton className={styles.accordionItemButton}>
              {accordion_title}
              <FaPlus className={styles.iconOpen} />
              <FaMinus className={styles.iconClose} />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className={styles.accordionItemPanel}>
            <div
              dangerouslySetInnerHTML={{
                __html: accordion_content,
              }}
            />
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  </div>
)

NewsFeatured.propTypes = {
  accordions: PropTypes.arrayOf(
    PropTypes.shape({
      accordion_title: PropTypes.string.isRequired,
      accordion_content: PropTypes.node.isRequired,
    }).isRequired
  ).isRequired,
}

export default NewsFeatured
