import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import {
  FaMapMarkerAlt,
  FaMobileAlt,
  FaClock,
  FaStickyNote,
  FaEnvelope,
} from 'react-icons/fa'

import SocialIcon from 'components/SocialIcon/SocialIcon'

import styles from './Contact.module.scss'
import gridStyles from 'styles/grid.module.scss'

export const query = graphql`
  fragment contactFragment on wordpress__wp_contact {
    title
    acf {
      address_line_1
      address_line_2
      address_line_3
      postcode
      telephone {
        telephone_label
        telephone_number
      }
      email {
        email_label
        email_address
      }
      opening_times {
        opening_time
      }
      contact_social {
        social_network
        link_to_page
      }
      notes
      uprn
    }
  }
`

const Contact = ({
  title,
  acf: {
    uprn,
    address_line_1,
    address_line_2,
    address_line_3,
    postcode,
    telephone,
    email,
    opening_times,
    notes,
    contact_social,
  },
}) => (
  <div className={styles.contactBlock} id="contactBlock">
    <div className={styles.titleSocialBlock}>
      <div className={gridStyles.lbhRow}>
        <div
          className={
            contact_social
              ? gridStyles.lbhColumnTwoThirds
              : gridStyles.lbhColumnFull
          }
        >
          {title && (
            <h2 id="contactTitle" dangerouslySetInnerHTML={{ __html: title }} />
          )}
        </div>
        {contact_social && (
          <div className={gridStyles.lbhColumnOneThird}>
            <div className={styles.socialBlock}>
              <span>Follow us on: </span>
              {contact_social.map(({ link_to_page, social_network }) => (
                <a
                  key={social_network}
                  href={link_to_page && link_to_page}
                  title={social_network}
                >
                  <SocialIcon name={social_network} />
                  <title>{social_network}</title>
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
    <div className={gridStyles.lbhRow}>
      <div className={gridStyles.lbhColumnOneThird}>
        {address_line_1 && (
          <div className={styles.contactMeta}>
            <h3>
              <FaMapMarkerAlt />
              Address
            </h3>
            <div>
              <div>{address_line_1}</div>
              {address_line_2 && <div>{address_line_2}</div>}
              {address_line_3 && <div>{address_line_3}</div>}
              {postcode && <div>{postcode}</div>}
            </div>
          </div>
        )}
        {telephone && (
          <div className={styles.contactMeta}>
            <h3>
              <FaMobileAlt />
              Telephone
            </h3>
            <div>
              <ul>
                {telephone.map(({ telephone_label, telephone_number }) => (
                  <li key={telephone_label}>
                    {telephone_label && <div>{telephone_label}</div>}
                    {telephone_number && (
                      <a href={`tel:${telephone_number}`}>{telephone_number}</a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {email && (
          <div className={styles.contactMeta}>
            <h3>
              <FaEnvelope />
              Email
            </h3>
            <div>
              <ul>
                {email.map(({ email_label, email_address }) => (
                  <li key={email_label}>
                    {email_label && <div>{email_label}</div>}
                    {email_address && (
                      <a href={`mailto:${email_address}`}>{email_address}</a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        {opening_times && (
          <div className={styles.contactMeta}>
            <h3>
              <FaClock />
              Opening times
            </h3>
            <div>
              <ul>
                {opening_times.map(({ opening_time }) => (
                  <li key={opening_time}>{opening_time}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {notes && (
          <div className={styles.contactMeta}>
            <h3>
              <FaStickyNote />
              Notes
            </h3>
            <div>
              <div dangerouslySetInnerHTML={{ __html: notes }} />
            </div>
          </div>
        )}
      </div>

      <div className={gridStyles.lbhColumnTwoThirds}>
        {uprn && (
          <div className={styles.contactMap}>
            <iframe
              title="contact map"
              src={`https://map.hackney.gov.uk/ContactMapV2/index.html?uprn=${uprn}`}
              scrollwheel="false"
            ></iframe>
          </div>
        )}
      </div>
    </div>
  </div>
)

Contact.propTypes = {
  title: PropTypes.node,
  acf: PropTypes.shape({
    uprn: PropTypes.string,
    address_line_1: PropTypes.string,
    address_line_2: PropTypes.string,
    address_line_3: PropTypes.string,
    postcode: PropTypes.string,
    telephone: PropTypes.arrayOf(
      PropTypes.shape({
        telephone_label: PropTypes.string,
        telephone_number: PropTypes.string,
      })
    ),
    email: PropTypes.arrayOf(
      PropTypes.shape({
        email_label: PropTypes.string,
        email_address: PropTypes.string,
      })
    ),
    opening_times: PropTypes.arrayOf(
      PropTypes.shape({
        opening_time: PropTypes.string,
      })
    ),
    notes: PropTypes.node,
    contact_social: PropTypes.arrayOf(
      PropTypes.shape({
        link_to_page: PropTypes.string,
        social_network: PropTypes.string,
      })
    ),
  }).isRequired,
}

export default Contact
