import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { graphql } from 'gatsby'
import { FaTimesCircle as OpenIcon } from 'react-icons/fa'

import styles from './CardGrid.module.scss'

export const query = graphql`
  fragment cardGridFragment on WordPressAcf_card_grid {
    title
    cards {
      name
      desc_short
      desc_long
      cta
      image {
        source_url
      }
    }
  }
`

const CardGrid = ({ title, cards }) => {
  const [isVisible, toggleVisible] = useState(null)
  const modalRef = useRef(null)

  const handleToggleVisibility = cardName => {
    toggleVisible(isVisible === cardName ? null : cardName)
  }

  useEffect(() => {
    const focusableElements = modalRef.current
      ? modalRef.current.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        )
      : []
    const firstElement = focusableElements[0]
    const lastElement = focusableElements[focusableElements.length - 1]

    const trapFocus = e => {
      if (e.key === 'Tab') {
        if (e.shiftKey) {
          if (document.activeElement === firstElement) {
            lastElement.focus()
            e.preventDefault()
          }
        } else {
          if (document.activeElement === lastElement) {
            firstElement.focus()
            e.preventDefault()
          }
        }
      }
    }

    if (isVisible && modalRef.current) {
      firstElement.focus()
      document.addEventListener('keydown', trapFocus)
    }

    return () => {
      document.removeEventListener('keydown', trapFocus)
    }
  }, [isVisible])

  return (
    <div className={styles.container}>
      {title && <h3>{title}</h3>}
      <div className={styles.body}>
        {cards.map(card => {
          const isCardVisible = isVisible === card.name
          const label = `Click to show more details for ${card.name}`
          return (
            <div
              key={card.name}
              className={cx(styles.card, {
                [styles.isActive]: isCardVisible,
              })}
              onClick={() => handleToggleVisibility(card.name)}
              role="presentation"
            >
              <div className={styles.imageBox}>
                <button aria-label={label}>
                  <OpenIcon />
                </button>
                {card.image && (
                  <img
                    className={styles.image}
                    src={card.image.source_url}
                    alt=""
                  />
                )}
              </div>
              <strong>
                <a className={styles.cta} href={card.cta}>
                  {card.name}
                </a>
              </strong>
              <div className={styles.desc_short}>{card.desc_short}</div>
              {isCardVisible && (
                <div
                  className={styles.desc_long}
                  ref={modalRef}
                  role="dialog"
                  aria-modal="true"
                  tabIndex="-1"
                >
                  <button
                    aria-label="close"
                    onClick={() => handleToggleVisibility(null)}
                  >
                    <OpenIcon />
                  </button>
                  <div dangerouslySetInnerHTML={{ __html: card.desc_long }} />
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

CardGrid.propTypes = {
  title: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      desc_short: PropTypes.string.isRequired,
      desc_long: PropTypes.node.isRequired,
      cta: PropTypes.string.isRequired,
      image: PropTypes.shape({
        source_url: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
}

export default CardGrid
