import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { graphql } from 'gatsby'
import IframeResizer from 'iframe-resizer-react'

import { getPropsFromDOMString } from 'utils/DOM'

import styles from './PageContent.module.scss'

export const query = graphql`
  fragment contentFragment on WordPressAcf_content {
    content
  }
`

const PageContent = ({ content, isFeatured, isIframe, hasDynamicHeight }) =>
  isIframe && hasDynamicHeight ? (
    <IframeResizer
      heightCalculationMethod="bodyScroll"
      {...getPropsFromDOMString(content)}
    />
  ) : (
    <div
      className={cx(styles.container, {
        [styles.featured]: isFeatured,
        [styles.iframe]: isIframe,
      })}
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  )

PageContent.propTypes = {
  content: PropTypes.node.isRequired,
  isFeatured: PropTypes.bool,
  isIframe: PropTypes.bool,
  hasDynamicHeight: PropTypes.bool,
}

export default PageContent
