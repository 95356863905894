import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import styles from './ImagesBlock.module.scss'
import gridStyles from 'styles/grid.module.scss'

export const query = graphql`
  fragment imageBlockFragment on wordpress__wp_media {
    source_url
    title
    alt_text
  }
`

const ImagesBlock = ({ images, portrait }) => (
  <section className={cx(gridStyles.lbhRow, styles.imageSection)}>
    {images.map(({ title, source_url, alt_text }) => (
      <figure
        key={title}
        className={cx(
          styles.imageFigure,
          {
            [styles.imagePortrait]: portrait,
          },
          {
            [styles.imageFull]: images.length === 1,
            [styles.imageHalf]: images.length === 2,
            [styles.imageThird]: images.length === 3,
          }
        )}
      >
        <span className={styles.imageWrapper}>
          <img src={source_url} className={styles.image} alt={alt_text} />
        </span>
        {title && (
          <figcaption
            className={styles.imageCaption}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
      </figure>
    ))}
  </section>
)

ImagesBlock.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      source_url: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  portrait: PropTypes.bool,
}

export default ImagesBlock
