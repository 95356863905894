import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { graphql } from 'gatsby'

import { FaMapMarkerAlt, FaPoundSign, FaDraftingCompass } from 'react-icons/fa'

import styles from './CommercialProperty.module.scss'
import gridStyles from 'styles/grid.module.scss'

export const query = graphql`
  fragment commercialPropertyFragment on WordPressAcf_commercial_property {
    title
    use
    area
    address
    rent
    brochure
    under_offer
    image {
      source_url
    }
    note
  }
`

const CommercialProperty = ({
  property: {
    title,
    use,
    area,
    address,
    rent,
    brochure,
    under_offer,
    note,
    image: { source_url } = {},
  },
}) => (
  <div className={styles.container}>
    <div className={gridStyles.lbhColumnHalf}>
      <div
        className={styles.image}
        style={{
          backgroundImage: `url(${source_url})`,
        }}
      >
        {under_offer && <span className={styles.ribbon}>under offer</span>}
      </div>
    </div>
    <div className={cx(gridStyles.lbhColumnHalf, styles.description)}>
      <span className={styles.use}>{use}</span>
      <h3>{title}</h3>
      <div>
        <FaMapMarkerAlt />{' '}
        {brochure ? <a href={brochure}>{address}</a> : { address }}
      </div>
      <div>
        <FaDraftingCompass /> {area}
      </div>
      <div>
        <FaPoundSign /> {rent}
      </div>
      {note && <div>{note}</div>}
    </div>
  </div>
)

CommercialProperty.propTypes = {
  property: PropTypes.shape({
    title: PropTypes.string.isRequired,
    use: PropTypes.string.isRequired,
    area: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    rent: PropTypes.string.isRequired,
    brochure: PropTypes.string,
    under_offer: PropTypes.bool,
    note: PropTypes.string,
    image: PropTypes.shape({
      source_url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default CommercialProperty
