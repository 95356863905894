import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { graphql } from 'gatsby'

import Button from 'components/Button/Button'

import styles from './Question.module.scss'

export const query = graphql`
  fragment questionFragment on WordPressAcf_question {
    #title
    subtitle
    options {
      title
      description
    }
    action
  }
`

const NewsFeatured = ({ title, subtitle, options, action }) => {
  const [selected, setSelected] = useState()
  const [showDescription, setShowDescription] = useState(false)
  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        setShowDescription(true)
      }}
      className={styles.container}
    >
      <div className={styles.subtitle}>{subtitle}</div>
      <div className={styles.options}>
        {options.map(q => (
          <div
            className={cx(styles.option, {
              [styles.optionChecked]: selected === q.title,
            })}
            key={q.title}
          >
            <input
              id={q.title}
              name={title || subtitle}
              type="radio"
              value={q.title}
              checked={selected === q.title}
              onChange={({ target: { value } }) => {
                setSelected(value)
                setShowDescription(false)
              }}
            />
            <label htmlFor={q.title}>{q.title}</label>
          </div>
        ))}
      </div>
      <Button type="submit">{action}</Button>
      {showDescription && (
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: options.find(o => o.title === selected).description,
          }}
        />
      )}
    </form>
  )
}

NewsFeatured.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired
  ),
  action: PropTypes.string,
}

export default NewsFeatured
