import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Button from 'components/Button/Button'

import styles from './CardHero.module.scss'
import gridStyles from 'styles/grid.module.scss'

export const query = graphql`
  fragment cardHeroFragment on WordPressAcf_card_hero {
    title
    name
    description
    cta_text
    cta_link
    image {
      source_url
    }
  }
`

const CardHero = ({
  card: {
    title,
    name,
    description,
    cta_text,
    cta_link,
    image: { source_url } = {},
  },
}) => (
  <div className={styles.container}>
    <div>
      <div className={gridStyles.lbhColumnOneFourth}>
        <img className={styles.image} src={source_url} alt={``} />
        <div className={styles.name}>
          {title}
          <div>{name}</div>
        </div>
      </div>
      <div className={gridStyles.lbhColumnThreeFourth}>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {cta_link && (
          <a href={cta_link} className={styles.readMore}>
            <Button type="secondary" href={cta_link}>
              {cta_text || 'Read more'}
            </Button>
          </a>
        )}
      </div>
    </div>
  </div>
)

CardHero.propTypes = {
  card: PropTypes.shape({
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.node.isRequired,
    cta_text: PropTypes.string,
    cta_link: PropTypes.string,
    image: PropTypes.shape({
      source_url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default CardHero
