import React, { useEffect, useState, useCallback } from 'react'
import cx from 'classnames'
import throttler from 'lodash.throttle'
import { FaArrowUp } from 'react-icons/fa'

import styles from './BackToTop.module.scss'

const BackToTop = () => {
  let scrollTimer = -1
  const [visible, setVisible] = useState()
  const [hideMobile, setHideMobile] = useState()
  const listenToScroll = useCallback(() => {
    scrollTimer !== -1 && clearTimeout(scrollTimer)
    setVisible(
      document.body.scrollTop || document.documentElement.scrollTop > 400
    )
    setHideMobile(false)
    scrollTimer = setTimeout(() => setHideMobile(true), 1000)
  })
  const scrollToTop = () => {
    document.querySelector('#top').focus()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  useEffect(() => {
    window.addEventListener('scroll', throttler(listenToScroll))
    return () => window.removeEventListener('scroll', listenToScroll)
  }, [])
  if (!visible) {
    return null
  }
  return (
    <button
      onClick={scrollToTop}
      className={cx(styles.backToTop, { [styles.hideMobile]: hideMobile })}
    >
      Back to top{' '}
      <span>
        <FaArrowUp alt="Back to the top of the page" />
      </span>
    </button>
  )
}

export default BackToTop
