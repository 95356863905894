import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { graphql } from 'gatsby'

import styles from './StepByStep.module.scss'

export const query = graphql`
  fragment stepByStepFragment on WordPressAcf_step_by_step {
    steps {
      title
      body
      name
    }
  }
`

const NewsFeatured = ({ steps }) => {
  let stepNumber = 1
  const [selected, setSelected] = useState({})
  const allVisible =
    Object.values(selected).filter(sel => sel).length === steps.length
  return (
    <div className={styles.container}>
      <button
        className={styles.showAll}
        onClick={() =>
          setSelected(
            steps.reduce(
              ({ sel, index }, { name }) => ({
                sel: {
                  ...sel,
                  [name || index++]: !allVisible,
                },
                index,
              }),
              { sel: {}, index: 1 }
            ).sel
          )
        }
      >
        {allVisible ? 'Hide all' : 'Show all'}
      </button>
      <ul className={styles.list}>
        {steps.map(step => {
          const stepName = step.name ? step.name : stepNumber++
          const isVisible = Boolean(selected[stepName])
          const isStepNameNumber = !isNaN(parseInt(step.name, 10))
          return (
            <li key={stepName}>
              <div className={styles.header}>
                <button
                  aria-expanded={isVisible ? 'true' : 'false'}
                  aria-controls={`step-${stepName}`}
                  onClick={() =>
                    setSelected({ ...selected, [stepName]: !isVisible })
                  }
                >
                  <span
                    className={cx(styles.step, {
                      [styles.stepAlt]: Boolean(step.name) && !isStepNameNumber,
                      [styles.stepNum]: Boolean(!step.name) || isStepNameNumber,
                    })}
                  >
                    {stepName}
                  </span>
                  <span className={styles.titles}>{step.title}</span>
                  <span className={styles.command} aria-hidden="true">
                    {isVisible ? 'Hide' : 'Show'}
                  </span>
                </button>
              </div>
              <div
                id={`step-${stepName}`}
                className={cx(styles.content, {
                  [styles.contentVisible]: isVisible,
                })}
                dangerouslySetInnerHTML={{ __html: step.body }}
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

NewsFeatured.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      name: PropTypes.string,
      body: PropTypes.node.isRequired,
    }).isRequired
  ).isRequired,
}

export default NewsFeatured
