import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import {
  Accordion,
  Blocks,
  ImagesBlock,
  PageContent,
  PageSectionLinks,
  CommercialProperty,
  CardHero,
  CardGrid,
  FlickrAlbum,
  StepByStep,
  Question,
} from './components'

export const query = graphql`
  fragment pageBlocksFragment on wordpress__PAGEAcf {
    pageBlocks: lbh_page_builder_page {
      __typename
      ...accordionFragment
      ... on WordPressAcf_content {
        ...contentFragment
      }
      ... on WordPressAcf_featured_message {
        featured_message
      }
      ... on WordPressAcf_anchor_links {
        ...anchorLinksFragment
      }
      ... on WordPressAcf_iframes {
        iframe
        hasDynamicHeight
      }
      ... on WordPressAcf_image_block_single_portrait {
        image_one {
          ...imageBlockFragment
        }
      }
      ... on WordPressAcf_image_block_single {
        image_one {
          ...imageBlockFragment
        }
      }
      ... on WordPressAcf_image_block_double {
        image_one {
          ...imageBlockFragment
        }
        image_two {
          ...imageBlockFragment
        }
      }
      ... on WordPressAcf_image_block_triple {
        image_one {
          ...imageBlockFragment
        }
        image_two {
          ...imageBlockFragment
        }
        image_three {
          ...imageBlockFragment
        }
      }
      ... on WordPressAcf_commercial_property {
        ...commercialPropertyFragment
      }
      ... on WordPressAcf_card_hero {
        ...cardHeroFragment
      }
      ... on WordPressAcf_card_grid {
        ...cardGridFragment
      }
      ... on WordPressAcf_flickr_album {
        ...flickrAlbumFragment
      }
      ... on WordPressAcf_blocks {
        ...blocksFragment
      }
      ... on WordPressAcf_step_by_step {
        ...stepByStepFragment
      }
      ... on WordPressAcf_question {
        ...questionFragment
      }
    }
  }
`

const PageBuilder = ({ pageBlocks }) =>
  pageBlocks.map((pageBlock, i) => {
    switch (pageBlock.__typename) {
      case 'WordPressAcf_content':
        return <PageContent key={i} content={pageBlock.content} />
      case 'WordPressAcf_accordion':
        return <Accordion key={i} accordions={pageBlock.accordions} />
      case 'WordPressAcf_featured_message':
        return (
          <PageContent
            key={i}
            content={pageBlock.featured_message}
            isFeatured
          />
        )
      case 'WordPressAcf_iframes':
        return (
          <PageContent
            key={i}
            content={pageBlock.iframe}
            hasDynamicHeight={pageBlock.hasDynamicHeight}
            isIframe
          />
        )
      case 'WordPressAcf_flickr_album':
        return <FlickrAlbum key={i} {...pageBlock} />
      case 'WordPressAcf_anchor_links':
        return <PageSectionLinks key={i} links={pageBlock.anchor_links} />
      case 'WordPressAcf_image_block_single':
      case 'WordPressAcf_image_block_double':
      case 'WordPressAcf_image_block_triple':
        return (
          <ImagesBlock
            key={i}
            images={[
              pageBlock.image_one,
              pageBlock.image_two,
              pageBlock.image_three,
            ].filter(Boolean)}
          />
        )
      case 'WordPressAcf_image_block_single_portrait':
        return (
          <ImagesBlock
            key={i}
            images={[
              pageBlock.image_one,
              pageBlock.image_two,
              pageBlock.image_three,
            ].filter(Boolean)}
            portrait={true}
          />
        )
      case 'WordPressAcf_commercial_property':
        return <CommercialProperty key={i} property={pageBlock} />
      case 'WordPressAcf_card_hero':
        return <CardHero key={i} card={pageBlock} />
      case 'WordPressAcf_card_grid':
        return <CardGrid key={i} {...pageBlock} />
      case 'WordPressAcf_step_by_step':
        return <StepByStep key={i} {...pageBlock} />
      case 'WordPressAcf_blocks':
        return (
          <Blocks
            key={i}
            blocks={pageBlock.blocks}
            blocks_title={pageBlock.blocks_title}
            is_home={false}
          />
        )
      case 'WordPressAcf_question':
        return <Question key={i} {...pageBlock} />
      default:
        return null
    }
  })

PageBuilder.propTypes = {
  pageBlocks: PropTypes.arrayOf(
    PropTypes.shape({
      __typename: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export default PageBuilder
