import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

export const query = graphql`
  fragment flickrAlbumFragment on WordPressAcf_flickr_album {
    album_id
    preview_url
    title
  }
`

const CardHero = ({ album_id, preview_url, title }) => (
  <a
    data-flickr-embed="true"
    href={`https://www.flickr.com/photos/hackneycouncil/albums/${album_id}`}
    title={title}
  >
    <img src={preview_url} alt={title} style={{ width: '100%' }} />
  </a>
)

CardHero.propTypes = {
  album_id: PropTypes.string.isRequired,
  preview_url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default CardHero
