import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import he from 'he'

import readingTime from 'reading-time'

import { flatQuery } from 'utils/queries'
import Layout from 'components/Layout/Layout'
import Announcements from 'components/Announcements/Announcements'
import BackToTop from 'components/BackToTop/BackToTop'
import Contact from 'components/Contact/Contact'
import Breadcrumb from 'components/Breadcrumbs/Breadcrumbs'
import PageBuilder from 'components/PageBuilder/PageBuilder'

import gridStyles from 'styles/grid.module.scss'
import pageStyles from './SinglePage.module.scss'

import { getServicesFromPath } from 'utils/services'
import puzzelWebchat from 'utils/puzzelWebchat'

class SinglePageTemplate extends Component {
  state = {}
  componentDidMount() {
    if (this.content) {
      const readtime = readingTime(
        this.content.innerText || this.content.textContent
      )
      this.setState({
        readtime,
        readTimeVal: readtime.text.toString().charAt(0),
      })
    }
  }
  render() {
    const { readtime, readTimeVal } = this.state
    const {
      data: {
        page,
        contact,
        site: { siteMetadata },
        currentService = {},
        allServices,
      },
      location,
    } = this.props
    const pageServices = getServicesFromPath(
      currentService.path,
      flatQuery(allServices)
    )
    const breadcrumbs = pageServices.filter(
      ({ show_or_hide_from_menu }) => show_or_hide_from_menu === 'show'
    )
    const puzzelWebchatGuid = pageServices
      .reverse()
      .find(({ acf }) => acf?.puzzel_guid)?.acf?.puzzel_guid
    puzzelWebchatGuid &&
      puzzelWebchat(siteMetadata.puzzelCustomerKey, puzzelWebchatGuid)
    return (
      <Layout
        title={`${he.decode(page.title)} | ${siteMetadata.title}`}
        location={location}
        announcements={
          <Announcements service={page.service} pageId={page.wordpress_id} />
        }
      >
        <div className={gridStyles.lbhContainer}>
          <div className={gridStyles.lbhRow} id="top">
            <div className={gridStyles.lbhColumnFull}>
              <Helmet>
                <script
                  async
                  src="//embedr.flickr.com/assets/client-code.js"
                  charset="utf-8"
                ></script>
              </Helmet>
              <div className={pageStyles.singlePage}>
                <Breadcrumb
                  home={{ url: '/', name: 'Home' }}
                  breadcrumbs={breadcrumbs}
                />
                {page.title && (
                  <h1 dangerouslySetInnerHTML={{ __html: page.title }} />
                )}
                {readTimeVal > 1 && readtime && (
                  <div className={pageStyles.readingTime}>{readtime.text}</div>
                )}
                <div
                  className={pageStyles.pageContent}
                  ref={el => {
                    this.content = el
                  }}
                >
                  <PageBuilder pageBlocks={page.acf.pageBlocks} />
                  {page.modified && (
                    <div id="contact" className={pageStyles.pageUpdated}>
                      Page updated on: {page.modified} <br></br>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {contact && <Contact {...contact} />}
        </div>
        <BackToTop />
      </Layout>
    )
  }
}

SinglePageTemplate.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
        puzzelCustomerKey: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    page: PropTypes.shape({
      acf: PropTypes.shape({
        pageBlocks: PropTypes.arrayOf(PropTypes.shape({}).isRequired)
          .isRequired,
      }).isRequired,
      title: PropTypes.node.isRequired,
      modified: PropTypes.string.isRequired,
      service: PropTypes.array.isRequired,
      wordpress_id: PropTypes.number.isRequired,
    }).isRequired,
    currentService: PropTypes.shape({ path: PropTypes.string.isRequired })
      .isRequired,
    contact: PropTypes.shape({}).isRequired,
    allServices: PropTypes.shape({}).isRequired,
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
}

export default SinglePageTemplate

export const pageQuery = graphql`
  query($slug: String!, $wordpress_id: Int!, $contact: Int) {
    site {
      siteMetadata {
        title
        puzzelCustomerKey
      }
    }
    page: wordpressPage(slug: { eq: $slug }) {
      title
      slug
      modified(formatString: "D MMMM YYYY")
      content
      service
      wordpress_id
      acf {
        ...pageBlocksFragment
        contact
      }
    }
    contact: wordpressWpContact(wordpress_id: { eq: $contact }) {
      ...contactFragment
    }
    currentService: wordpressWpService(wordpress_id: { eq: $wordpress_id }) {
      path
    }
    allServices: allWordpressWpService {
      edges {
        node {
          slug
          name
          show_or_hide_from_menu
          acf {
            puzzel_guid
          }
        }
      }
    }
  }
`
