import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

import styles from './PageSectionLinks.module.scss'

export const query = graphql`
  fragment anchorLinksFragment on WordPressAcf_anchor_links {
    anchor_links {
      anchor_title
      anchor_link
    }
  }
`
const PageSectionLinks = ({ links }) => {
  const focusHeading = id => {
    const targetAnchor = document.getElementById(id)
    if (targetAnchor) {
      const heading = targetAnchor.closest('h2')
      if (heading) {
        heading.setAttribute('tabindex', '-1')
        heading.focus()
      }
    }
  }

  useEffect(() => {
    const anchorLinks = document.querySelectorAll('h2 a[href^="#"]')
    anchorLinks.forEach(link => {
      link.addEventListener('click', event => {
        event.preventDefault()
        const targetId = link.getAttribute('href').substring(1)
        focusHeading(targetId)
      })
    })

    return () => {
      anchorLinks.forEach(link => {
        link.removeEventListener('click')
      })
    }
  }, [focusHeading])

  return (
    <nav className={styles.container}>
      <h2 className={styles.title}>On this page:</h2>
      <ul>
        {links.map(({ anchor_link, anchor_title }) => (
          <li key={anchor_title}>
            <Link
              to={anchor_link}
              onClick={event => {
                event.preventDefault()
                focusHeading(anchor_link.substring(1))
              }}
            >
              {anchor_title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

PageSectionLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      anchor_link: PropTypes.string.isRequired,
      anchor_title: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export default PageSectionLinks
